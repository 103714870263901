import React, {useState, useEffect, useRef} from 'react'
import { MapContainer, TileLayer, Marker, Popup, WMSTileLayer,useMap } from "react-leaflet";
import {GeoJSON} from 'react-leaflet';
import "leaflet/dist/leaflet.css";
// import L, { map } from "leaflet";

import { useDispatch, useSelector } from "react-redux";
import { getGeo } from "../store/actions/geoAction";

// const magicKingdomLatLng = [13.8, 108]
export default function Banner(props){
    const position = [13.8, 108]

    const dispatch = useDispatch();
    const geoDataJSON = useSelector((state) => state.geoDataJSON);
    const geoJsonLayer = useRef(null);
    const { loading, error, data } = geoDataJSON;
    
    const map = useMap()
    useEffect(()=>{
        // const {current = {}} = mapRef;
        // console.log(mapRef);
        // const {leafletElement: map} = current;
        setTimeout(()=>{
            map.flyTo(position,13,{duration:3})
        },1000)
    },[])

    useEffect(() => {
      dispatch(getGeo());
    }, [dispatch]);

    return (
        <div className="fms_banner">
             <MapContainer  center={position} zoom={14} scrollWheelZoom={false} className="map">
               <WMSTileLayer
                    url="https://maps.innogroup.vn/inno_public/gwc/service/wms"
                    version='1.1.0'
                    opacity={1}
                    transparent
                    layers="inno_public:mp_taynguyen_forest"
                    srs="EPSG:4326"
                    format="image/png"                    
                />
                {/* <Marker position={position}>
                <Popup>
                    Hello this is popup. <br /> What is the problem with image.
                </Popup>
                </Marker> */}
                {/* {!loading && !error &&(
                    [...data.features].map((feature,i)=>(
                        // console.log(feature)
                        <GeoJSON key={`geojson-${i}`} data={feature} ref={geoJsonLayer}/>
                    ))
                )} */}
                {/* {!loading && !error &&(
                    console.log(JSON.parse(data))
                )} */}
            </MapContainer>
            <a href="#" className="btn_op_filter">
                <img src="images/ic-3.png" alt="" />
            </a>

            <div className="box_filter">
                <a href="#" className="btn_close_filter">
                    <img src="images/ic-4.png" alt="" />
                </a>
				 {/* <!-- tablet & mobile remove className "scrollbar-macosx scrollbar_filter"--> */}
                <div className="box_filter_content scrollbar-macosx scrollbar_filter">
                    <div className="box_filter_top">
                        <h5>
                            Lọc theo
                        </h5>
                        <div className="box_filter_list">
                            <div className="box_filter_all">
                                <select className="box_filter_select">
                                    <option>Hà Nội</option>
                                </select>
                            </div>
                            <div className="box_filter_all">
                                <select className="box_filter_select">
                                    <option>Chọn huyện</option>
                                </select>
                            </div>
                            <div className="box_filter_all">
                                <select className="box_filter_select">
                                    <option>Chọn xã</option>
                                </select>
                            </div>
                            <div className="box_filter_all">
                                <input type="text" className="box_filter_date" placeholder="Từ ngày" />
                            </div>
                            <div className="box_filter_all">
                                <input type="text" className="box_filter_date" placeholder="Đến ngày" />
                            </div>
                            <div className="box_filter_all">
                                <button type="submit" className="btn btn-fil">Lọc</button>
                            </div>
                        </div>
                        <div className="filter_mb_title_center">
                            <img src="images/ic-18.png" alt="" /> Cảnh báo
                        </div>

                        <div className="aht_list">
                            {/* <!-- tablet & mobile add className "filter_mb_slide"--> */}
                            <div className="swiper-container">
                                <div className="swiper-wrapper swiper-wrapper-filter ">
                                    <div className="swiper-slide">
                                        <a href="" className="filter_result_item">
                                            <h6>
                                                Cháy 6 ha rừng xã Đồng Sơn, Bắc Giang
                                            </h6>
                                            <span className="filter_result_place">
                                                Xã Đồng Sơn, TP. Bắc Giang, Bắc Giang
                                            </span>
                                            <span className="filter_result_place filter_result_date">
                                                19.02.2021
                                            </span>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <a href="" className="filter_result_item">
                                            <h6>
                                                Cháy 6 ha rừng xã Đồng Sơn, Bắc Giang
                                            </h6>
                                            <span className="filter_result_place">
                                                Xã Đồng Sơn, TP. Bắc Giang, Bắc Giang
                                            </span>
                                            <span className="filter_result_place filter_result_date">
                                                19.02.2021
                                            </span>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <a href="" className="filter_result_item">
                                            <h6>
                                                Cháy 6 ha rừng xã Đồng Sơn, Bắc Giang
                                            </h6>
                                            <span className="filter_result_place">
                                                Xã Đồng Sơn, TP. Bắc Giang, Bắc Giang
                                            </span>
                                            <span className="filter_result_place filter_result_date">
                                                19.02.2021
                                            </span>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <a href="" className="filter_result_item">
                                            <h6>
                                                Cháy 6 ha rừng xã Đồng Sơn, Bắc Giang
                                            </h6>
                                            <span className="filter_result_place">
                                                Xã Đồng Sơn, TP. Bắc Giang, Bắc Giang
                                            </span>
                                            <span className="filter_result_place filter_result_date">
                                                19.02.2021
                                            </span>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <a href="" className="filter_result_item">
                                            <h6>
                                                Cháy 6 ha rừng xã Đồng Sơn, Bắc Giang
                                            </h6>
                                            <span className="filter_result_place">
                                                Xã Đồng Sơn, TP. Bắc Giang, Bắc Giang
                                            </span>
                                            <span className="filter_result_place filter_result_date">
                                                19.02.2021
                                            </span>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <a href="" className="filter_result_item">
                                            <h6>
                                                Cháy 6 ha rừng xã Đồng Sơn, Bắc Giang
                                            </h6>
                                            <span className="filter_result_place">
                                                Xã Đồng Sơn, TP. Bắc Giang, Bắc Giang
                                            </span>
                                            <span className="filter_result_place filter_result_date">
                                                19.02.2021
                                            </span>
                                        </a>
                                    </div>
                                </div>                                                               
                                <div className="swiper-pagination swiper-pagination-filter"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    )
}