import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAhts } from "../store/actions/ahtAction";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function Aht(props) {
  const dispatch = useDispatch();
  const ahtsListData = useSelector((state) => state.ahtsList);
  const { loading, error, ahts } = ahtsListData;
  useEffect(() => {
    dispatch(getAhts());
  }, [dispatch]);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  return (
    <div className="fms_aht">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="aht_title">
              <h2 className="fms_title">ảnh hiện trường</h2>
              <div className="fms_txt">
                Habitant sit elit commodo quam diam.
              </div>
            </div>
            {loading ? (
              "Loading..."
            ) : error ? (
              error.message
            ) : (
              <div className="aht_list">
                  <Swiper 
                        className="swiper-container aht_slide"
                        spaceBetween={12}
                        slidesPerView={4}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current;
                            swiper.params.navigation.nextEl = navigationNextRef.current;
                            swiper.navigation.update();
                        }}
                        pagination={{ clickable: true }}
                   >
                        {ahts.map((item,index) => (
                            <SwiperSlide className="swiper-slide" key={index}>
                                <div className="aht_slide_item">
                                    <a data-fancybox="gallery" href={item.thumb}>
                                        <img class="img-fancybox" src={item.thumb} alt=""/>
                                        <img src={item.image} alt="" className="fancybox-seen" />
                                    </a>                                
                                </div>
                            </SwiperSlide>                      
                        ))}
                  <div className="swiper-button-next swiper-button-next-aht" ref={navigationPrevRef}/>
                  <div className="swiper-button-prev swiper-button-prev-aht" ref={navigationNextRef}/>
                  </Swiper>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
