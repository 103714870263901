import React, {useState, useEffect} from 'react'
import Header from "../components/Header";
import Banner from "../components/Banner";
import Aht from "../components/Aht";
import Footer from "../components/Footer";
import Modal from "../components/Modal";
import Gopy from '../components/Gopy';
export default function Home(props){
    return (
        <>
        <Header/>
        <Banner/>
        <Aht/>
        <Gopy/>
        <Footer/>
        <Modal/>        
        </>
    )
}