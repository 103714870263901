// AHT - Anh Hien Truong
import {GET_GOPY, GOPY_ERROR} from '../types'

const initialState = {
    gopy:[],
    loading:true
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_GOPY:
        return {
            ...state,
            gopy:action.payload,
            loading:false

        }
        case GOPY_ERROR:
            return{
                loading: false, 
                error: action.payload 
            }
        default: return state
    }

}