import React, { useState, useEffect } from "react";
import {
  //   BrowserRouter as Router,
  //   Switch,
  //   Route,
  Link,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getGopy } from "../store/actions/gopyAction";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
export default function Gopy(props) {
  const dispatch = useDispatch();
  const gopyListData = useSelector((state) => state.gopyList);
  const { loading, error, gopy } = gopyListData;
  useEffect(() => {
    dispatch(getGopy());
  }, [dispatch]);

  return (
    <div className="fms_gpfb">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="gpfb_top">
              <div className="gpfb_title">
                <h2 className="fms_title">góp ý</h2>
                <div className="fms_txt">
                  Habitant sit elit commodo quam diam.
                </div>
              </div>
              <Link className="btn btn-gpfb" to="/gop-y">
                <img src="images/ic-16.png" /> Gửi góp ý
              </Link>
              <div className="clearfix"></div>
            </div>
            {loading ? (
              "Loading..."
            ) : error ? (
              error.message
            ) : (
              <div className="aht_list">
                <Swiper
                  className="swiper-container gpfb_slide"
                  spaceBetween={12}
                  slidesPerView={3}
                  pagination={{ clickable: true }}
                >
                  {gopy.map((item, index) => (
                    <SwiperSlide className="swiper-slide" key={index}>
                      <div className="gpfb_slide_item">
                        " {item.content} "<span>{item.author}</span>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
            <div className="gpfb_bot">
              <img src="images/img-6.png" alt="" className="img-6" />
              <div className="gpfb_bot_content">
                <div className="gpfb_content_left">
                  <h5>Tải ứng dụng</h5>
                  <span>
                    Eget erat nec orci nisl massa sagittis. Diam tincidunt sed
                    pulvinar morbi. Dictum vestibulu
                  </span>
                </div>
                <div className="gpfb_content_right">
                  <div className="gpfb_content_right_app">
                    <a href="">
                      <img src="images/img-7.png" alt="" />
                    </a>
                    <a href="">
                      <img src="images/img-8.png" alt="" />
                    </a>
                  </div>
                  <img className="img-qr" src="images/img-9.png" alt="" />
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
