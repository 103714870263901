import React, {useState, useEffect} from 'react'
export default function Feedback(props){
    return (
        <div className="fms_feedback">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="feedback_main">
                        <div className="feedback_main_left">
                            <img src="images/img-10.png" alt="" />
                        </div>
                        <div className="feedback_main_right">
                            <h5>
                                Hộp thư góp ý
                            </h5>
                            <form className="form_feedback">
                                <div className="feedback_row">
                                    <div className="feedback_col">
                                        <div className="feedback_input">
                                            <label>
                                                Email
                                            </label>
                                            <input type="text" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="feedback_col">
                                        <div className="feedback_input">
                                            <label>
                                                Điện thoại
                                            </label>
                                            <input type="text" placeholder="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="feedback_row">
                                    <div className="feedback_input">
                                        <label>
                                            Nội dung
                                        </label>
                                        <textarea placeholder=""></textarea>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-feedback" >Gửi thư</button>
                            </form>
                            <div className="feedback_noti">
                                * Cần hỗ trợ khác vui lòng liên hệ ban quản trị email <a href="mailto:admin@gmail.com">admin@gmail.com</a>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}