import React, {useState, useEffect} from 'react'
export default function Footer(props){
    return (
        <div className="fms_footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="fms_footer_content">
                            <span>
                                Tổng cục Lâm Nghiệp
                            </span>
                            <h3>
                                cục kiểm lâm
                            </h3>
                            Giấy phép cung cấp thông tin trên Internet số 121/GP-BC cấp ngày 30/3/2007
                            Ghi rõ nguồn "www.kiemlam.org.vn" khi sử dụng lại thông tin từ website này
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}