import React, {useState, useEffect} from 'react'
import Header from "../components/Header";
import Feedback from "../components/Feedback";
import Footer from "../components/Footer";
import Modal from "../components/Modal";
export default function GopY(props){
    return (
        <>
        <Header/>
        <Feedback/>       
        <Footer/>
        <Modal/>        
        </>
    )
}