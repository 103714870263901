import React, {useState, useEffect} from 'react'
export default function Modal(props){
    return (
        <div className="modal fade data_forest" id="popup-data" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="data_forest_content">
                        <h5>
                            THÔNG TIN ĐIỂM CHÁY
                        </h5>
                        <div className="data_forest_txt">
                            Tỉnh Quảng Nam
                        </div>
                        <div className="table_data_box">
                            <div className="table_data_other scrollbar-macosx scrollbar_forest">
                                <table className="table_data_forest" width="100%">
                                    <tr>
                                        <td width="11.8%">
                                            #
                                        </td>
                                        <td width="56%">
                                            Huyện
                                        </td>
                                        <td>
                                            Số điểm cháy
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="11.8%">
                                            1
                                        </td>
                                        <td width="56%">
                                            Hiệp Đức
                                        </td>
                                        <td>
                                            154
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="11.8%">
                                            2
                                        </td>
                                        <td width="56%">
                                            Hiệp Đức
                                        </td>
                                        <td>
                                            154
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="11.8%">
                                            3
                                        </td>
                                        <td width="56%">
                                            Hiệp Đức
                                        </td>
                                        <td>
                                            154
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="11.8%">
                                            4
                                        </td>
                                        <td width="56%">
                                            Hiệp Đức
                                        </td>
                                        <td>
                                            154
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="11.8%">
                                            5
                                        </td>
                                        <td width="56%">
                                            Hiệp Đức
                                        </td>
                                        <td>
                                            154
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="11.8%">
                                            6
                                        </td>
                                        <td width="56%">
                                            Hiệp Đức
                                        </td>
                                        <td>
                                            154
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="11.8%">
                                            7
                                        </td>
                                        <td width="56%">
                                            Hiệp Đức
                                        </td>
                                        <td>
                                            154
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="11.8%">
                                            8
                                        </td>
                                        <td width="56%">
                                            Hiệp Đức
                                        </td>
                                        <td>
                                            154
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="11.8%">
                                            9
                                        </td>
                                        <td width="56%">
                                            Hiệp Đức
                                        </td>
                                        <td>
                                            154
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="11.8%">
                                            10
                                        </td>
                                        <td width="56%">
                                            Hiệp Đức
                                        </td>
                                        <td>
                                            154
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}