// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import GopY from './pages/GopY';
function App() {
  return (
    <BrowserRouter>
    <Switch>
      <Route path="/" component={Home} exact={true} />
      <Route path="/gop-y" component={GopY} exact={true} />
      {/* <Route path="/gioi-thieu" component={Home} exact={true} />
      <Route path="/truy-xuat" component={Home} exact={true} />
      <Route path="/tin-tuc" component={Home} exact={true} />
      <Route path="/lien-he" component={Home} exact={true} />
      <Route path="/dang-nhap" component={Home} exact={true} />
      <Route path="/dang-ky" component={Home} exact={true} /> */}
    </Switch>
  </BrowserRouter>
  );
}

export default App;
