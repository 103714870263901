// AHT - Anh Hien Truong
export const GET_AHTS = 'GET_AHTS';
export const AHTS_ERROR = 'AHTS_ERROR'

// Gopy - Gop y
export const GET_GOPY = 'GET_GOPY';
export const GOPY_ERROR = 'GOPY_ERROR'

// GEO
export const GET_GEO = 'GET_GEO';
export const GEO_ERROR = 'GEO_ERROR'