// AHT - Anh Hien Truong
import {GET_AHTS, AHTS_ERROR} from '../types'

const initialState = {
    ahts:[],
    loading:true
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_AHTS:
        return {
            ...state,
            ahts:action.payload,
            loading:false

        }
        case AHTS_ERROR:
            return{
                loading: false, 
                error: action.payload 
            }
        default: return state
    }

}