import React, {useState, useEffect} from 'react'
export default function Header(props){
    return (
        <div className="fms_head">
            <a href="" className="fms_head_logo">
                <img src="images/logo-fms.png" alt="" />
            </a>
            <div className="fms_head_menu">
                <a href="#" className="mobile-op">
                    <img src="images/ic-2.png" alt="Hệ thống giám sát mất rừng (FMS)" title="Hệ thống giám sát mất rừng (FMS)" />
                </a>
                <div className="head_menu_content">
                    <a href="#" className="mobile-close">

                    </a>
                    <ul className="head_menu_list">
                        <li>
                            <a href="/">
                                Trang chủ
                            </a>
                        </li>
                        <li>
                            <a href="">
                                Giới thiệu
                            </a>
                        </li>
                        <li>
                            <a href="">
                                Truy xuất
                            </a>
                        </li>
                        <li>
                            <a href="">
                                Tin tức
                            </a>
                        </li>
                        <li>
                            <a href="">
                                Liên hệ
                            </a>
                        </li>
                    </ul>
                    <div className="head_menu_login">
                        <div className="menu_login_main">
                            <a href="">
                                đăng nhập 
                            </a>
                            /
                            <a href="">
                                đăng ký
                            </a>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
    )
}