// AHT - Anh Hien Truong
import {GET_GEO, GEO_ERROR} from '../types'
import axios from 'axios'
const jsonp = require('jsonp');
export const getGeo = () => async dispatch => {
    
    try{
        // const url = `${process.env.REACT_APP_BASE_URL}/ahts`;
        const url = `https://maps.innogroup.vn/inno_public/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=inno_public:mp_plot_changed&maxFeatures=50&outputFormat=text%2Fjavascript`;
        // const res = await axios.get(url)
        jsonp(url, {name:'parseResponse'}, (err, data) => {
            if (err) {
                // console.error(err.message);
                // console.log(err.message);
                dispatch( {
                    type: GEO_ERROR,
                    payload: err.message,
                })
            } else {
                // console.log(data.features)
                dispatch( {
                    type: GET_GEO,
                    payload:data
                })
            }
        });        
    }
    catch(error){
        dispatch( {
            type: GEO_ERROR,
            payload: error,
        })
    }

}