// GOP Y - Gop y
import {GET_GOPY, GOPY_ERROR} from '../types'
import axios from 'axios'

export const getGopy = () => async dispatch => {
    
    try{
        const url = `${process.env.REACT_APP_BASE_URL}/gopy`;
        const res = await axios.get(url)
        dispatch( {
            type: GET_GOPY,
            payload: res.data
        })
    }
    catch(error){
        dispatch( {
            type: GOPY_ERROR,
            payload: error,
        })
    }

}